import { useParams } from "react-router-dom";
import StoreItemDetailController from "../controllers/StoreItemDetailController";
import "./../css/StoreItemDetail.css";

const StoreItemDetail = () => {
  const params = useParams();
  return <StoreItemDetailController {...params}></StoreItemDetailController>;
};

export default StoreItemDetail;
