import "./../css/Home.css"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 

import HomeController from "./../controllers/HomeController";
const Home = () => {
    return <HomeController />; 
};

export default Home;
